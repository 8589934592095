import React from "react";
import {
  Grid,
  TextField,
  Button,
  Alert,
  Typography,
  ThemeProvider,
  FormControlLabel,
  Checkbox,
  FormControl,
  Link,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import Logo from "../../../assets/images/logo.png";
import { theme } from "../../layouts/ApplicationLayout";

export default function OtpPrompt({
  userRealName,
  otp,
  setOtp,
  alert,
  handleOnSubmit,
  rememberMe,
  setRememberMe,
  loading,
  reSendLinkVisible,
  reSendCode,
  channel,
}) {
  const inputRef = React.useRef(null);

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const reSendCodeBridge = (e) => {
    reSendCode(e);
    setOtp("");
    inputRef.current.focus();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs" sx={{ marginTop: "10%" }}>
        <Grid container maxWidth="xs">
          <Grid item xs={12} mb={6} textAlign="center">
            <img src={Logo} style={{ width: "15rem", height: "auto" }}></img>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3, mx: "auto" }}>
            <Typography variant="h6" textAlign="center">
              Hola {userRealName}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3, mx: "auto" }}>
            <Typography variant="body2" textAlign="center">
              Debes ingresar el código secundario enviado a tu{" "}
              {channel == "whatsapp" ? "WhatsApp" : " email"} para continuar.
            </Typography>
          </Grid>
          {alert && (
            <Grid item xs={12} sx={{ mb: 3, mx: "auto" }}>
              <Alert severity="error" sx={{ mx: "auto" }}>
                {alert}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} textAlign="center">
            <Box>
              <Grid container rowSpacing={3}>
                <Grid item xs={12} sx={{ mx: "auto" }} mt={2}>
                  <FormControl fullWidth>
                    <TextField
                      inputRef={inputRef}
                      autoFocus
                      required
                      id="otp"
                      label="Código Secundario"
                      value={otp}
                      size="small"
                      onChange={handleOtpChange}
                      disabled={loading}
                      helperText={
                        <Link
                          underline="hover"
                          color="primary"
                          sx={{ cursor: "pointer" }}
                          onClick={reSendCodeBridge}
                          display={reSendLinkVisible ? "block" : "none"}
                        >
                          Reenviar código
                        </Link>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={rememberMe}
                          onChange={() => setRememberMe((prev) => !prev)}
                          disabled={loading}
                        />
                      }
                      label="Mantener sesión abierta"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Button
                      sx={{ height: "3.2rem" }}
                      size="large"
                      variant="contained"
                      disabled={loading || !otp}
                      onClick={handleOnSubmit}
                    >
                      Continuar
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          position: "fixed",
          bottom: "0",
          flexGrow: 1,
          padding: "0.5rem 0.5rem",
        }}
      >
        <Typography sx={{ color: "gray" }}>
          CPT Salmón&reg; 2024 - Todos los derechos reservados
        </Typography>
      </Box>
    </ThemeProvider>
  );
}
