import React from "react";
import {
  Grid,
  TextField,
  Button,
  Link,
  Alert,
  Typography,
  ThemeProvider,
  FormControl,
  Stack,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import Logo from "../../../assets/images/logo.png";
import { theme } from "../../layouts/ApplicationLayout";
import OtpPrompt from "./OtpPrompt";
import setupAxiosInstance from "../../utils/axios";

export default function Login({ url, alert, passwordResetPath }) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [otpRequired, setOtpRequired] = React.useState(false);
  const [userRealName, setUserRealName] = React.useState("");
  const [channel, setChannel] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);
  const [otpAlert, setOtpAlert] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const myAxios = React.useRef(setupAxiosInstance(axios, false));
  const [reSendLinkVisible, setReSendLinkVisible] = React.useState(false);
  const [capsLocked, setCapsLocked] = React.useState(false);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const reSendCode = (e) => {
    handleOnSubmit(e, true);
  };

  const handleOnSubmit = (e, reSendingCode = false) => {
    e.preventDefault();
    setOtpAlert(null);
    setLoading(true);
    myAxios.current
      .post(url, {
        user: {
          email: username,
          password: password,
          otpAttempt: reSendingCode ? "init" : otp || "init",
          rememberMe: rememberMe,
        },
      })
      .then((response) => {
        if (response.data.status === "otp_required") {
          setOtpRequired(true);
          setOtpAlert("");
          setReSendLinkVisible(false);
          setUserRealName(response.data.userRealName);
          setChannel(response.data.channel);
        } else window.location.href = "/";
      })
      .catch((error) => {
        if (error.response.status === 401 && otpRequired) {
          const msg = error.response.data.error;
          if (msg === "Email o contraseña inválidos.") {
            setOtpAlert("Código de verificación inválido.");
          } else {
            setOtpAlert(msg);
          }
          setReSendLinkVisible(true);
        } else window.location.href = "/";
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    let timeout;
    if (otpRequired) {
      timeout = setTimeout(
        () => {
          setReSendLinkVisible(true);
        },
        3 * 60 * 1000,
      );
    }

    return () => clearTimeout(timeout);
  }, [otpRequired]);

  return (
    <>
      {!otpRequired && (
        <>
          <ThemeProvider theme={theme}>
            <Container maxWidth="xs" sx={{ marginTop: "10%" }}>
              <Grid container maxWidth="xs">
                <Grid item xs={12} mb={6}>
                  <Stack spacing={2}>
                    <img
                      src={Logo}
                      style={{
                        width: "6rem",
                        height: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <Typography variant="h5" textAlign="center" sx={{ mb: 3 }}>
                      Monitoreo de Peces
                    </Typography>
                  </Stack>
                </Grid>
                {alert && (
                  <Grid item xs={12} sx={{ mb: 3, mx: "auto" }}>
                    <Alert severity="error" sx={{ mx: "auto" }}>
                      {alert}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Box component="form" onSubmit={handleOnSubmit}>
                    <Grid container rowSpacing={3}>
                      <Grid item xs={12} sx={{ mx: "auto" }}>
                        <FormControl fullWidth>
                          <TextField
                            autoFocus
                            required
                            id="username"
                            label="Usuario"
                            value={username}
                            size="small"
                            autoComplete="email"
                            onChange={handleUsernameChange}
                            disabled={loading}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            required
                            id="password"
                            type="password"
                            label="Contraseña"
                            autoComplete="current-password"
                            value={password}
                            size="small"
                            onKeyDown={(e) =>
                              setCapsLocked(e.getModifierState("CapsLock"))
                            }
                            onChange={handlePasswordChange}
                            disabled={loading}
                            helperText={
                              capsLocked
                                ? "⚠️ Bloqueo de mayúsculas está activado"
                                : " "
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Button
                            sx={{ height: "3.2rem" }}
                            type="submit"
                            size="large"
                            variant="contained"
                            disabled={loading}
                          >
                            Iniciar Sesión
                          </Button>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <Link
                          sx={{ fontFamily: "Roboto" }}
                          href={passwordResetPath}
                          underline="hover"
                        >
                          Cambiar o recuperar contraseña
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                position: "fixed",
                bottom: "0",
                flexGrow: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <Typography sx={{ color: "gray" }}>
                CPT Salmón&reg; 2024 - Todos los derechos reservados
              </Typography>
            </Box>
          </ThemeProvider>
        </>
      )}
      {otpRequired && (
        <OtpPrompt
          userRealName={userRealName}
          setOtp={setOtp}
          otp={otp}
          rememberMe={rememberMe}
          setRememberMe={setRememberMe}
          handleOnSubmit={handleOnSubmit}
          alert={otpAlert}
          reSendCode={reSendCode}
          reSendLinkVisible={reSendLinkVisible}
          channel={channel}
        />
      )}
    </>
  );
}
