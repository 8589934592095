import React from "react";

import {
  Grid,
  TextField,
  Button,
  Link,
  Alert,
  Typography,
  ThemeProvider,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import Logo from "../../../assets/images/logo.png";
import { theme } from "../../layouts/ApplicationLayout";

export default function ResendInstructions({
  url,
  alert,
  loginPath,
  passwordResetPath,
}) {
  const [email, setEmail] = React.useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    axios
      .post(url, { user: { email: email } })
      .then(() => {
        window.location.href = "/";
      })
      .catch(() => {
        window.location.href = "/";
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs" sx={{ marginTop: "10%" }}>
        <Grid container maxWidth="xs">
          <Grid item xs={12} mb={6} textAlign="center">
            <img src={Logo} style={{ width: "15rem", height: "auto" }}></img>
          </Grid>
          {alert && (
            <Grid item xs={12} sx={{ mb: 3, mx: "auto" }}>
              <Alert severity="error" sx={{ maxWidth: "80%", mx: "auto" }}>
                {alert}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5" textAlign="center" sx={{ mb: 3 }}>
              ¿No recibiste las instrucciones?
            </Typography>
            <Box component="form" onSubmit={handleOnSubmit}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "80%" }}
                    autoFocus
                    required
                    id="email"
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    sx={{ width: "80%", height: "3.2rem" }}
                    size="large"
                    variant="contained"
                  >
                    Reenviar instrucciones
                  </Button>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Link
                    sx={{ fontFamily: "Roboto" }}
                    href={loginPath}
                    underline="hover"
                  >
                    Volver a iniciar sesión
                  </Link>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Link
                    sx={{ fontFamily: "Roboto" }}
                    href={passwordResetPath}
                    underline="hover"
                  >
                    ¿Olvidaste tu contraseña?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          position: "fixed",
          bottom: "0",
          flexGrow: 1,
          padding: "0.5rem 0.5rem",
        }}
      >
        <Typography sx={{ color: "gray" }}>
          CPT Salmón&reg; 2024 - Todos los derechos reservados
        </Typography>
      </Box>
    </ThemeProvider>
  );
}
