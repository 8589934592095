import ReactOnRails from "react-on-rails";
import Login from "../bundles/components/login/Login";
import ResetPassword from "../bundles/components/login/ResetPassword";
import ResendInstructions from "../bundles/components/login/ResendInstructions";
import ChangePassword from "../bundles/components/login/ChangePassword";
import OtpPrompt from "../bundles/components/login/OtpPrompt";
import "../bundles/utils/robotoFonts";

ReactOnRails.register({
  Login,
  ResetPassword,
  ChangePassword,
  ResendInstructions,
  OtpPrompt,
});
