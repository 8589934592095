import React from "react";
import {
  Grid,
  TextField,
  Button,
  Link,
  Alert,
  Typography,
  ThemeProvider,
  FormControl,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import Logo from "../../../assets/images/logo.png";
import { theme } from "../../layouts/ApplicationLayout";

export default function ChangePassword({ alert, loginPath }) {
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs" sx={{ marginTop: "10%" }}>
        <Grid container maxWidth="xs">
          <Grid item xs={12} mb={6} textAlign="center">
            <img src={Logo} style={{ width: "15rem", height: "auto" }}></img>
          </Grid>
          {alert && (
            <Grid item xs={12} sx={{ mb: 3, mx: "auto" }}>
              <Alert severity="error" sx={{ maxWidth: "80%", mx: "auto" }}>
                {alert}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5" textAlign="center" sx={{ mb: 3 }}>
              Crea una nueva contraseña
            </Typography>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    required
                    id="user_password"
                    name="user[password]"
                    type="password"
                    label="Contraseña"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    size="small"
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    required
                    id="user_password_confirmation"
                    name="user[password_confirmation]"
                    type="password"
                    label="Confirma tu contraseña"
                    value={passwordConfirmation}
                    onChange={(event) =>
                      setPasswordConfirmation(event.target.value)
                    }
                    size="small"
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Button
                    sx={{ height: "3.2rem" }}
                    size="large"
                    variant="contained"
                    type="submit"
                  >
                    Cambiar contraseña
                  </Button>
                </FormControl>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Link
                  sx={{ fontFamily: "Roboto" }}
                  href={loginPath}
                  underline="hover"
                >
                  Volver a iniciar sesión
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          position: "fixed",
          bottom: "0",
          flexGrow: 1,
          padding: "0.5rem 0.5rem",
        }}
      >
        <Typography sx={{ color: "gray" }}>
          CPT Salmón&reg; 2024 - Todos los derechos reservados
        </Typography>
      </Box>
    </ThemeProvider>
  );
}
